<template>
  <div class="flex p-5">
    <div @click="listClick " class="CDKbtn t-c color-white">抽奖</div>
    <div class="lists">
      <div
        class="list"
        :class="{
          'start-btn': item.oid === 0,
          disabled: item.oid === 0 && isRunning,
          active: activeIndex === index,
        }"
        :data-index="index"
        v-for="(item, index) in prises"
        :key="item.oid"
      >
        <span class="name">
          <span v-if="item.oid > 0"></span>
          <img height="75px" :src="item.oimg" alt />
          <span style="height:23px" class="color-white itemName f-13 flex items-center justify-center text-xs">
            {{
            item.oname
            }}
          </span>
          <span class="price color-white f-13" v-if="![0, 11].includes(item.oid)">
            {{
            ` ${item.oprice}`
            }}
          </span>
        </span>
      </div>
    </div>

    
  </div>
</template>
 
<script>
import { getCDK } from "../../../api/index";
export default {
  data() {
    return {
      prises: [],
      winningOrder: "", // 中几等奖
      isRunning: false, // 是否正在抽奖
      startSpeed: 150, // 初始动画速度
      speed: "", // 动画实时速度
      interval: null, // 定时器实例
      maxAnimationTime: 3000, // 最大抽奖动画时长
      activeIndex: "", // 当前走到的index
      rorateArr: [0, 1, 2, 3, 4, 5, 6, 7, 8], // 动画路径数组
      rotateIndex: 0, // 路径数组走到第一格
      startTime: "", // 开始时间
      endTime: "" // 结束时间
    };
  },
  components: {},

  computed: {
    // 动画运行时长
    rotateTime() {
      return this.endTime - this.startTime;
    },
    speedIntervalValue() {
      return this.startSpeed / 10;
    }
  },
  created() {
    this.$bus.$on("fuli", res => {
      this.prises = res;
      // this.prises.forEach((a, i) => {
      //   a["level"] = i + 1;
      // });
      // console.log(this.prises);
    });
    this.getPrises();
  },
  props: ["cdk"],
  methods: {
    getPrises() {
      this.prises.forEach(i => {
        i.id = this.randomId();
      });
    },
    randomId() {
      return Math.random();
    },
    listClick() {
      // console.log(this.cdk);
      getCDK(this.cdk).then(res => {
        console.log(res);
      });
      this.start();
    },
    // 抽奖
    start() {
      if (this.isRunning) {
        return;
      }
      this.isRunning = true;
      this.reset();
      const timeout = setTimeout(() => {
        this.intervalFn();
        clearTimeout(timeout);
      }, this.speed);
    },
    intervalFn() {
      clearInterval(this.interval);
      this.interval = null;
      if (!this.isRunning) {
        return;
      }
      // 变动画速度（逐渐加快）
      this.speed -= this.speedIntervalValue;

      if (this.speed <= 50) {
        // 最快速度
        this.speed = 50;
      }

      // 距离动画时间结束不到3秒时，动画逐渐减慢
      if (this.maxAnimationTime - this.rotateTime <= 2000) {
        this.speed += this.speedIntervalValue * 2; // 这里为什么加两倍，因为再次进入intervalFn方法，默认会减一倍，所以这里实际上是相当于加一倍
      }

      if (this.rotateTime > this.maxAnimationTime) {
        // 超过时长了
        if (this.winningOrder == this.prises[this.activeIndex].oid) {
          // 动画跑到了中奖奖品那
          this.isRunning = false;
          console.log(
            `抽奖结束，奖品${this.winningOrder}等奖，用时：${(
              this.rotateTime / 1000
            ).toFixed(2)}秒`
          );

          return;
        }
      }

      this.rotate();
      this.interval = setInterval(this.intervalFn, this.speed);
    },
    // 旋转高亮算法
    rotate() {
      if (this.rotateIndex === this.rorateArr.length) {
        this.rotateIndex = 0;
      }
      this.activeIndex = this.rorateArr[this.rotateIndex];
      this.endTime = new Date().getTime();

      this.rotateIndex += 1;
    },
    // 重置
    reset() {
      this.startTime = new Date().getTime();
      this.endTime = this.startTime;
      this.speed = this.startSpeed;
      this.rotateIndex = 0;
    }
  }
};
</script>
<style lang="scss" scoped>
.name {
  color: #e7b781;
  position: relative;
  .price {
    position: absolute;
    top: -18px;
    left: 50%;
    transform: translate(-50%, 0px);
  }
}
.itemName {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  // 没置超出部分显示省略号
  overflow: hidden;
  text-overflow: ellipsis;
  line-height:23px;
}
.CDKbtn {
  width: 130px;
  height: 50px;
  background: url("~@/assets/images/Welfare/btn.png") no-repeat;
  background-size: 100% 100%;
  line-height: 42px;
  margin: 10px auto;
}
$primary-color: #2ca1f4;

.btn {
  width: 150px;
  height: 150px;
}

.lists {
  width: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .list {
    position: relative;
    width: 180px;
    height: 143px;
    padding: 30px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    background: url("~@/assets/images/Welfare/noactive.png") no-repeat;
    background-size: 100% 100%;
    color: $primary-color;
    margin: 10px;
    &.active {
      background: url("~@/assets/images/Welfare/active.png") no-repeat;
      background-size: 100% 100%;
      border-radius: 15px;
    }
    &.start-btn {
      cursor: pointer;
      color: #fff;
      background: $primary-color;
      &.disabled {
        cursor: not-allowed;
        background: #999;
      }
    }
    .name {
      text-align: center;
    }
    .image {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }
}

@media (max-width: 550px) {
  .lists {
    width: 100%;
    justify-content: space-around;
    .list {
      width: 48%;

      margin: 0;
    }
  }
  .name {
    .price {
      position: absolute;
      top: -25px;
      left: 50%;
      transform: translate(-50%, 0px);
      font-size: 13px;
    }
    img {
      width: 80px !important;
    }
  }
  .itemName {
    line-height: 1;
  }
}
</style>